const HeadingTitle = ({title}) => {

    return (
        <div style={divStyle}>
        <h2 style={h2Styles}>{title}</h2>
        </div>
    );
};
const divStyle = {
    Padding: "10px 30px",
    backgroundColor: "#8EB0C6",
    display:"flex",
    justifyContent:"space-between"
};
const h2Styles = { 
    color: "black",
    fontSize: "30px",
    fontWeight: "500",
    borderBottom: "2px solid black",
    width: "max-content",
    paddingBottom: "5px",
};

export default HeadingTitle;
