import { useContext } from "react";
import { Link } from "react-router-dom"
import CartContext from "../../context/CartContext";

const HeaderMiddle = () => {
    
    const { cartItemsLength } = useContext (CartContext)
    return (  
    <div className="header-middle">
        <Link to="/" className="header-middle-logo">
            <b>Iqraa</b>
            <i className="bi bi-book"> </i>
            <b>Library</b>
        </Link>

        <Link to="/cart" className="header-middle-cart-wrapper">
        {cartItemsLength > 0 && (
             <b className="cart-notification">{cartItemsLength}</b>
        )}
            <i className="bi bi-cart2"></i>
        </Link>
    </div> );
}
export default HeaderMiddle;