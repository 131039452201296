import "./slider.css";
import FirstBook from "../../images/book2.png";

const Slider = () => {
    return ( 
    <div className="slider-container">
        <div className="slider-wrapper">
            <div className="slide first-slide">
                <div className="slide-img-wrapper">
                    <img src={FirstBook} alt="" />
                </div>
                <div className="slide-info-wrapper">
                    <h1 className="slide-info-title">Book Store</h1>
                    <p className="slide-info-desc">It's not just reading. It's living the adventure.</p>
                </div>
            </div>
        </div>
    </div> )
}
export default Slider;