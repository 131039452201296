import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./contact.css";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any field is empty
        if (!formData.name.trim() || !formData.email.trim() || !formData.subject.trim() || !formData.message.trim()) {
            toast.error("Please fill in all the fields.");
            return;
        }

        try {
            const response = await fetch("http://87.106.113.91:8000/api/message", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (data.success) {
                toast.success(data.massage || "Message sent successfully!");
            } else {
                toast.error(data.massage || "Failed to send the message.");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred. Please try again.");
        }
    };

    return (
        <section className="contact">
            <ToastContainer />
            <div className="contact-wrapper">
                <div className="contact-item">
                    <div className="contact-item-icon">
                        <i className="bi bi-house-fill"></i>
                        Address
                    </div>
                    <p className="contact-item-text">Syria-Damascus</p>
                </div>

                <div className="contact-item">
                    <div className="contact-item-icon">
                        <i className="bi bi-telephone-fill"></i>
                        Phone
                    </div>
                    <p className="contact-item-text">00963929843025</p>
                </div>

                <div className="contact-item">
                    <div className="contact-item-icon">
                        <i className="bi bi-envelope-fill"></i>
                        Email
                    </div>
                    <p className="contact-item-text">info@iqraalibrary.com</p>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="contact-form">
                <h2 className="contact-form-title">Contact us form</h2>
                <div className="contact-input-wrapper">
                    <input
                        type="text"
                        placeholder="Name *"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Subject *"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Email *"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <textarea
                    className="contact-textarea"
                    placeholder="Your Message *"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="5"
                    required
                ></textarea>
                <button type="submit" className="contact-btn">Send</button>
            </form>
        </section>
    );
};

export default Contact;
