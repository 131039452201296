import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./forms.css";
import { register_service } from './apiService'; // added the api_service path (register_service)

const Register = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState(""); //  'name' instead of 'username'
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // new state for confirm password

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (email.trim() === "") return toast.error("Email is required");
    if (name.trim() === "") return toast.error("Name is required"); //  'name' instead of 'username'
    if (password.trim() === "") return toast.error("Password is required");
    if (confirmPassword.trim() === "") return toast.error("Confirm password is required");
    if (password !== confirmPassword) return toast.error("Passwords do not match");

    // Updated: Use `password_confirmation` instead of `confirmPassword`
    const register_Data = { 
      email, 
      name, 
      password, 
      password_confirmation: confirmPassword // Ensure Laravel receives the correct field
    };

    try {
      const response = await register_service(register_Data); // register_data: the user data we send to the register_api_service
      console.log('User registered successfully:', response);
      toast.success("Registration successful!");
    } catch (error) {
      console.error('Error registering user:', error);
      toast.error("Registration failed!");
    }
  };

  return (
    <div className="form-wrapper">
      <ToastContainer />
      <h1 className="form-title">Create new account</h1>
      <form onSubmit={formSubmitHandler} className="form">
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          placeholder="Email"
        />
        <input
          value={name} //  'name' instead of 'username'
          onChange={e => setName(e.target.value)} //  'setName' instead of 'setUsername'
          type="text"
          placeholder="Name"
        />
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
          placeholder="Password"
        />
        <input
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          type="password"
          placeholder="Confirm Password"
        />
        <button className="form-btn">Register</button>
      </form>
      <div className="form-footer">
        Already have an account?{" "}
        <Link to="/login" className="form-link">
          Login
        </Link>
      </div>
    </div>
  );
};

export default Register;
