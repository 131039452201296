import axios from "axios";

const API_BASE_URL = "http://87.106.113.91:8000/api/auth"; // defining the base api url (without endpoints)

export const register_service = (register_Data) => {
  // return axios.get(`${API_BASE_URL}/ahmad`, register_Data);
  return axios.post(`${API_BASE_URL}/register`, register_Data); // post(send) the register data to back-end
};

export const login_service = (login_Data) => {
  return axios.post(`${API_BASE_URL}/login`, login_Data); // post(send) the login data to back-end
};
