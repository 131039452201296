const OrderSummary = ({totalPrice}) => {
    return (
        <div className="cart-order-summary">
        <div className="order-summary-title">Order Summary</div>
        <div className="order-summary-item">
            <span>Shopping Cost</span>
            <span>
              0
            </span>
        </div>

        <div className="order-summary-item">
            <span>Discount</span>
            <span>
                0
            </span>
        </div>

        <div className="order-summary-item">
            <span>Total</span>
            <span>
                ${totalPrice}  
            </span>
            <div>
            <button className="cart-pay-btn">
                Pay Now
            </button>
        </div>
        </div>
    </div>
    );
}

export default OrderSummary;