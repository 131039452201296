import "./about.css";

const About = () => {
    return( <section className="about" >
        <h1>About us</h1>
        <p> We are a team of reading lovers who founded this library because we believe that the book
             is the highest thing in existence and that the book must be with you wherever you are
              , whether at home , at work , or even al university , 
              so that it is easy to access regardless of the person's circumstances.
               From here came the slogan of Iqra Library (Your book is with you wherever you are).</p>
               <p>
            <strong>
                August, 2024
            </strong>
        </p>
        <p>
            <strong>
                Version: 1.0.0
            </strong>
        </p>
    </section> );
}

export default About;
