import { useContext, useState } from "react";
import Rating from "../book-slider/Rating";
import "./modal.css";
import { Link } from "react-router-dom";
import CartContext from "../../context/CartContext";

const Modal = ({ bookData, setOpenModal }) => {
  const { addToCart } = useContext(CartContext);
  const [qty, setQty] = useState(1);
  const { image, title, inStock, rating, reviews, author, price, id, path } =
    bookData;
  return (
    <div onClick={() => setOpenModal(false)} className="modal-container">
      <div
        onClick={(event) => event.stopPropagation()}
        className="modal-content"
        ><i
          onClick={() => setOpenModal(false)}
          className="bi bi-x-circle-fill modal-icon"
        ></i>
        <div className="modal-content-img">
          <img src={`/books/${image}`} alt={title} />
        </div>
        <div className="modal-content-info">
          <h5 className="modal-content-info-title">{title}</h5>
          <div className="modal-content-info-stock">
            <b>Status: </b> {inStock ? "in stock" : "not in stock"}
          </div>
          <Rating rating={rating} reviews={reviews} />
          <div className="modal-content-info-author">
            <b>Author: </b> {author}
          </div>
          <div className="modal-content-info-price">
            <b>Price: </b> ${price}
          </div>
          <div className="modal-add-to-cart">
            <input
              type="number"
              min="1"
              max="100"
              className="modal-add-to-cart-input"
              value={qty}
              onChange={(e) => setQty(e.target.value)}
            />
            <button
              onClick={() => addToCart({ ...bookData, quantity: qty })}
              className="modal-add-to-cart-btn"
            ><i className="bi bi-cart-plus"></i>
              Add to cart
            </button>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Link to={`/book/${id}`} className="modal-content-info-link">
              See more details
            </Link>
            <a download href={path} className="modal-content-info-link">
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
