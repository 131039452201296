import { Link } from "react-router-dom";
import "./forms.css";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login_service } from './apiService';  // added the api_service path (login_service)

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);

  // Form Submit Handler
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (email.trim() === "") return toast.error("Email is required");
    if (password.trim() === "") return toast.error("Password is required");

    const login_Data = { email, password };

    try {
      const response = await login_service(login_Data);  // login_data: the login user data we send to the login_api_service
      console.log('Login successful:', response);
      toast.success("Login successful!");
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error("Login failed!");
    }
  };

  // Show Password Handler
  const showPasswordHandler = () => {
    setshowPassword(prev => !prev);
  };

  return (
    <div className="form-wrapper">
      <ToastContainer />
      <h1 className="form-title">Login to your account</h1>
      <form onSubmit={formSubmitHandler} className="form">
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          placeholder="Email"
        />
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
        />
        {showPassword ? (
          <i onClick={showPasswordHandler} className="bi bi-eye-slash-fill show-password-icon"></i>
        ) : (
          <i onClick={showPasswordHandler} className="bi bi-eye-fill show-password-icon"></i>
        )}
        <button className="form-btn">Login</button>
      </form>
      <div className="form-footer">
        Don't have an account? {" "}
        <Link to="/register" className="form-link">
          Register
        </Link>
      </div>
    </div>
  );
};

export default Login;
