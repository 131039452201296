export const books = [
{
    id: 1,
    price: 13,
    rating:4,
    path: "/books/Malibu Rising.pdf",
    title: "Malibu Rising",
    author: "Taylor Jenkins Reid",
    reviews: "103,804",
    image: "Malibu-Rising.jpeg",
    printLength: 369,
    language: "English",
    PublicationDate: "27 May 2021",
    description: "Malibu Rising is a story about one unforgettable night in the life of a family: the night they each have to choose what they will keep from the people who made them, and what they will leave behind.",
    inStock: true,
   
    category: {
        first: true,
        second: false,
        third: false }
         
    },
    {
        id: 2,
        price: 10,
        rating: 3.8,
        path: "/books/The Art of Thinkiong Clearly.pdf",
        title: "The Art of Thinkiong Clearly",
        author: "Rolf Dobelli",
        reviews: "3,482",
        image: "The-art-of-thinkiong-clearly.jpeg",
        printLength: 384,
        language: "English",
        PublicationDate: "1 January 2011",
        description: "Simple, clear and always surprising, this indispensable book will change the way you think and transform your decision-making-work, at home, every day. It reveals, in 99 short chapters, the most common errors of judgment, and how to avoid them.",
        inStock: true,
        
        category: {
            first: true,
            second: false,
            third: false }
        },
    {
        id: 3,
        price: 11.5,
        rating: 4.7,
        path: "/books/More Important Than Money.pdf",
        title: "More Important Than Money",
        author: "Rober Kiyosaki",
        reviews: "7,769",
        image: "more-important-than-money.jpeg",
        printLength: 448,
        language: "English",
        PublicationDate: "4 July 2017",
        description:"Many people have million-dollar ideas. They’re confident that their new product or service or innovation will make them rich and that all their dreams will come true. The problem is: Most people don’t know how to turn their million-dollar idea into millions of dollars.",
        inStock: true,
        
        category: {
            first: true,
            second: false,
            third: false }
        },

        {
            id: 4,
            price: 14,
            rating: 4.6,
            path: "/books/Verity.pdf",
            title: "Verity",
            author: "Collen Hoover",
            reviews: "255,696",
            image: "verify.jpeg",
            printLength: 336,
            language: "English",
            PublicationDate: "7 December 2018",
            description:"Lowen Ashleigh is a struggling writer on the brink of financial ruin when she accepts the job offer of a lifetime. Jeremy Crawford, husband of bestselling author Verity Crawford, has hired Lowen to complete the remaining books in a successful series his injured wife is unable to finish.",
            inStock: true,
            
            category: {
                first: true,
                second: false,
                third: false }
            },
            {
                id: 5,
                price: 4.5,
                rating: 4.24,
                path: "/books/The Essential.pdf",
                title: "The Essential",
                author: "Oscar Wilde",
                reviews: "1,204",
                image: "The-Essential.jpeg",
                printLength: 450,
                language: "English",
                PublicationDate: "1 January 1898",
                description:"Collected her in one omnibus edition are Oscar Wilde's most important works including The Importance of Being Earnest, The Picture of Dorian Gray, Salome, Selected Poems of Oscar Wilde, The Soul of Man Under Socialism, The Happy Prince and Other Tales, and The Canterville Ghost. These works of poetry, fiction, drama, and prose encompass Wilde's entire career and they display his range of style and wit. Wilde is one of the most important writers in the history of the English language.",
                inStock: true,
                
                category: {
                    first: true,
                    second: false,
                    third: false }
                },
                {
                    id: 6,
                    price: 15,
                    rating: 4.1,
                    path: "/books/The Dark Side of the Light Chasers.pdf",
                    title: "The Dark Side of the Light Chasers",
                    author: "Debbie Ford",
                    reviews: "3,486",
                    image: "The-dark-side-of-the-light-chasers.jpg",
                    printLength: 208,
                    language: "English",
                    PublicationDate: "18 May 1998",
                    description:"We know the shadow by many names: alter ego, lower self, the dark twin, repressed self, id. Carl Jung once said that the shadow is the person you would rather not be. But even if you choose to hide your dark side, it will still cast a shadow, according to author Debbie Ford. Rather than reject the seemingly undesirable parts of ourselves, Ford offers advice on how to confront our shadows.",
                    inStock: true,
                    
                    category: {
                        first: true,
                        second: false,
                        third: false }
                    },
                    {
                        id: 7,
                        price: 22.5,
                        rating: 4.36,
                        path: "/books/Never Split the Difference.pdf",
                        title: "Never Split the Difference",
                        author: "Chris Voss",
                        reviews: "44,949",
                        image: "Never-Split-the-Difference.jpeg",
                        printLength: 288,
                        language: "English",
                        PublicationDate: "17 May 2016",
                        description:"After a stint policing the rough streets of Kansas City, Missouri, Chris Voss joined the FBI, where his career as a kidnapping negotiator brought him face-to-face with bank robbers, gang leaders, and terrorists. Never Split the Difference takes you inside his world of high-stakes negotiations, revealing the nine key principles that helped Voss and his colleagues succeed when it mattered the most when people lives were at stake.",
                        inStock: true,
                        
                        category: {
                            first: true,
                            second: false,
                            third: false }
                        },
                        {
                            id: 8,
                            price: 20,
                            rating: 4.13,
                            path: "/books/Time Management.pdf",
                            title: "Time Management",
                            author: "Brian Tracy",
                            reviews: "27,773",
                            image: "time-management.jpg",
                            printLength: 112,
                            language: "English",
                            PublicationDate: "1 January 2008",
                            description:"It’s a simple equation: the better you use your time, the more you will accomplish and the greater you will succeed. Imagine what you could accomplish with two more productive hours every single day.",
                            inStock: true,
                            
                            category: {
                                first: true,
                                second: false,
                                third: false }
                            },
                            {
                                id: 9,
                                price: 7,
                                rating: 4,
                                path: "/books/The Richest Man in Babylon.pdf",
                                title: "The Richest Man In Babylon",
                                author: "George S. Clason",
                                reviews: "11,361",
                                image: "The-richest-man-in-babylon.jpg",
                                printLength: 160,
                                language: "English",
                                PublicationDate: "1 January 2003",
                                description:"Powerful and proven techniques to manage your finances. Karen McCreadies interpretation of Clason's work illustrates the timeless nature of his insights by bringing them to life through modern case studies.",
                                inStock: true,
                                
                                category: {
                                    first: true,
                                    second: false,
                                    third: false }
                                },
                                {
                                    id: 10,
                                    price: 10,
                                    rating: 4.09,
                                    path: "/books/10 Minutes 38 Seconds in This Strange World.pdf",
                                    title: "10 Min 38 Sec in This Strange World",
                                    author: "Elif Shafak",
                                    reviews: "8,407",
                                    image: "10-min-38-sec.jpg",
                                    printLength: 312,
                                    language: "English",
                                    PublicationDate: "1 June 2019",
                                    description:"In the first minute following her death, Tequila Leila's consciousness began to ebb, slowly and steadily, like a tide receding from the shore. Her brain cells, having run out of blood, were now completely deprived of oxygen. But they did not shut down. Not right away.",
                                    inStock: true,
                                    
                                    category: {
                                        first: true,
                                        second: false,
                                        third: false }
                                    },
 {
                                        id: 11,
                                        price: 11,
                                        rating: 3.92,
                                        path: "/books/The Adventures of Tom Sawyer.pdf",
                                        title: "The Adventures of Tom Sawyer",
                                        author: "Mark Twain",
                                        reviews: "2,758",
                                        image: "the-adventures-of-tom-sawyer.jpg",
                                        printLength: 244,
                                        language: "English",
                                        PublicationDate: "1 June 1876",
                                        description:"It revolves around the youthful adventures of the novel's schoolboy protagonist, Thomas Sawyer, whose reputation precedes him for causing mischief and strife",
                                        inStock: true,
                                        
                                        category: {
                                            first: false,
                                            second: true,
                                            third: false }
                                        },

                                        {
                                            id: 12,
                                            price: 17.5,
                                            rating: 4.01,
                                            path: "/books/On Entering the Sea.pdf",
                                            title: "On Entering the Sea",
                                            author: "Nizar Qabbani",
                                            reviews: "2,758",
                                            image: "On-entering-the-sea.jpg",
                                            printLength: 184,
                                            language: "English",
                                            PublicationDate: "1 August 1995",
                                            description:"The volume's introduction asserts that Qabbani is a heroic champion of women's rights who, by equating beautywith liberty and justice, and celebrating women in all their strength and glory, challenges in his poetry his culture's cruel repression of women.",
                                            inStock: true,
                                            
                                            category: {
                                                first: false,
                                                second: true,
                                                third: false }
                                            },
                                            {
                                                id: 13,
                                                price: 10,
                                                rating: 4.24,
                                                path: "/books/Change Your Thoughts—Change Your Life.pdf",
                                                title: "Change Your Thoughts",
                                                author: "Wayne W. Dyer",
                                                reviews: "21,361",
                                                image: "change-your-thoughts-change-your-life.jpg",
                                                printLength: 421,
                                                language: "English",
                                                PublicationDate: "31 July 2007",
                                                description:"In this book, Dr. Wayne W. Dyer has reviewed hundreds of translations of the Tao Te Ching and has written 81 distinct essays on how to apply the ancient wisdom of Lao-tzu to today’s modern world.",
                                                inStock: true,
                                                
                                                category: {
                                                    first: false,
                                                    second: true,
                                                    third: false }
                                                },
                                                {
                                                    id: 14,
                                                    price: 12.5,
                                                    rating: 3.91,
                                                    path: "/books/The Alchemist.pdf",
                                                    title: "The Alchemist",
                                                    author: "Paulo Coelho",
                                                    reviews: "127,020",
                                                    image: "The-alchemist.jpg",
                                                    printLength: 182,
                                                    language: "English",
                                                    PublicationDate: "1 January 1998",
                                                    description:"Paulo Coelho's masterpiece tells the mystical story of Santiago, an Andalusian shepherd boy who yearns to travel in search of a worldly treasure.",
                                                    inStock: true,
                                                    
                                                    category: {
                                                        first: false,
                                                        second: true,
                                                        third: false }
                                                    },
                                                        {
                                                            id: 15,
                                                            price: 4,
                                                            rating: 4.23,
                                                            path: "/books/The Prophet.pdf",
                                                            title: "The Prophet",
                                                            author: "Khalil Gibran",
                                                            reviews: "15,147",
                                                            image: "the-prophet.jpg",
                                                            printLength: 127,
                                                            language: "English",
                                                            PublicationDate: "1 January 1923",
                                                            description:"The Prophet is a collection of poetic essays that are philosophical, spiritual, and, above all, inspirational. Gibran’s musings are divided into twenty-eight chapters covering such sprawling topics.",
                                                            inStock: true,
                                                            
                                                            category: {
                                                                first: false,
                                                                second: true,
                                                                third: false }
                                                            },
                                                            {
                                                                id: 16,
                                                                price: 10,
                                                                rating: 3.80,
                                                                path: "/books/The Old Man and the Sea.pdf",
                                                                title: "The Old Man and the Sea",
                                                                author: "Ernest Hemingway",
                                                                reviews: "42,158",
                                                                image: "Ernest-hemingway.jpg",
                                                                printLength: 96,
                                                                language: "English",
                                                                PublicationDate: "10 September 1952",
                                                                description:"This short novel, already a modern classic, is the superbly told, tragic story of a Cuban fisherman in the Gulf Stream and the giant Marlin he kills and loses—specifically referred to in the citation accompanying the author's Nobel Prize for literature in 1954.",
                                                                inStock: true,
                                                                
                                                                category: {
                                                                    first: false,
                                                                    second: true,
                                                                    third: false }
                                                                },
                                                                {
                                                                    id: 17,
                                                                    price: 5,
                                                                    rating: 4,
                                                                    path: "/books/Healing Is the New High.pdf",
                                                                    title: "Healing Is The New High",
                                                                    author: "Vex King",
                                                                    reviews: "42,185",
                                                                    image: "healing-is-the-new-high.jpg",
                                                                    printLength: 304,
                                                                    language: "English",
                                                                    PublicationDate: "13 April 2021",
                                                                    description:"Vex developed powerful inner healing techniques to help him break free from his troubled past, heal his emotional pain and trauma, and create a new and empowering belief system. ",
                                                                    inStock: true,
                                                                    
                                                                    category: {
                                                                        first: false,
                                                                        second: true,
                                                                        third: false }
                                                                    },
                                                                    {
                                                                        id: 18,
                                                                        price: 7,
                                                                        rating: 5,
                                                                        path: "/books/Introvert Survival Tactics.pdf",
                                                                        title: "Introvert Survival Tactics",
                                                                        author: "Patrick King",
                                                                        reviews: "17,696",
                                                                        image: "Introvert-survival-tactics.jpg",
                                                                        printLength: 115,
                                                                        language: "English",
                                                                        PublicationDate: "2 Febraury 2017",
                                                                        description:"Patrick King was massive introvert and struggled with what society told him to be for years, until he discovered how to make his introversion an advantage in his life. In this book, he will teach you how to feel completely at ease with yourself, while being the social powerhouse you want to be.",
                                                                        inStock: true,
                                                                        
                                                                        category: {
                                                                            first: false,
                                                                            second: true,
                                                                            third: false }
                                                                        },
                                                                        {
                                                                            id: 19,
                                                                            price: 6.5,
                                                                            rating: 4.28,
                                                                            path: "/books/The Picture of Dorian Gray.pdf",
                                                                            title: "The Picture Of Dorian Gray",
                                                                            author: "Oscan Wilde",
                                                                            reviews: "76,571",
                                                                            image: "The-picture-of-dorian-gray.jpg",
                                                                            printLength: 145,
                                                                            language: "English",
                                                                            PublicationDate: "1 July 1890",
                                                                            description:"In this celebrated work Wilde forged a devastating portrait of the effects of evil and debauchery on a young aesthete in late-19th-century England.",
                                                                            inStock: true,
                                                                            
                                                                            category: {
                                                                                first: false,
                                                                                second: true,
                                                                                third: false }
                                                                            },
                                                                            {
                                                                                id: 20,
                                                                                price: 7,
                                                                                rating: 4.36,
                                                                                path: "/books/In the Presence of Absence.pdf",
                                                                                title: "In The Presence Of Absence",
                                                                                author: "Mahmoud Darwish",
                                                                                reviews: "3,976",
                                                                                image: "In-the-presence-of-absence.jpg",
                                                                                printLength: 171,
                                                                                language: "English",
                                                                                PublicationDate: "17 January 2006",
                                                                                description:"By one of the most transcendent poets of this generation, a remarkable collection of prose poems that explores themes of love, pain, isolation, and connection. In this self-eulogy written in the final years of Mahmoud Darwish's life, Palestine becomes a metaphor for the injustice and pain of our contemporary moment.",
                                                                                inStock: true,
                                                                            
                                                                                category: {
                                                                                    first: false,
                                                                                    second: true,
                                                                                    third: false }
                                                                                },
                                                                                {
                                                                                    id: 21,
                                                                                    price: 10.50,
                                                                                    rating: 5,
                                                                                    path: "/books/Atomic Habits.pdf",
                                                                                    title: "Atomic Habits",
                                                                                    author: "James Clear",
                                                                                    reviews: "69,548",
                                                                                    image: "atomic-habits.jpg",
                                                                                    printLength: 320,
                                                                                    language: "English",
                                                                                    PublicationDate: "16 April 2018",
                                                                                    description:"Transform your life with tiny changes in behaviour, starting now. There's never been a better time to make a few tiny changes that will revolutionise your life.",
                                                                                    inStock: true,
                                                                                    
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                            
                                                                                },
                                                                                {
                                                                                    id: 22,
                                                                                    price: 14,
                                                                                    rating: 5,
                                                                                    path: "/books/Because you are God.pdf",
                                                                                    title: "لأنك الله",
                                                                                    author: "Ali Alfaifi",
                                                                                    reviews: "3,980",
                                                                                    image: "Because you are God.jpg",
                                                                                    printLength: 192,
                                                                                    language: "Arabic",
                                                                                    PublicationDate: "1 January 2016",
                                                                                    description:"لأنك الله.. لا خوفٌ.. ولا قلقُ ولا غروبٌ.. ولا ليلٌ.. ولا شفقُ لأنك الله.. قلبي كله أملُ لأنك الله.. روحي ملؤها الألقُ كلمات عن بعض أسماء الله حرص المؤلف علي بن جابر الفيفي أن يجعلها مما يفهمه متوسط الثقافة، ويستطيع قراءته المريض على سريره، والحزين بين دموعه، والمحتاج وسط كروبه",
                                                                                    inStock: true,
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
                                                                                {
                                                                                    id: 23,
                                                                                    price: 9.00,
                                                                                    rating: 5,
                                                                                    path: "/books/The Trouble With Hating You.pdf",
                                                                                    title: "The Trouble With Hating You",
                                                                                    author: "Sajni Patel",
                                                                                    reviews: "235",
                                                                                    image: "the-trouble-with-hating-you.jpg",
                                                                                    printLength: 352,
                                                                                    language: "English",
                                                                                    PublicationDate: "1 June 2019",
                                                                                    description:"Liya Thakkar is a successful biochemical engineer, takeout enthusiast, and happily single woman. The moment she realizes her parents' latest dinner party is a setup with the man they want her to marry, she's out the back door in a flash.",
                                                                                    inStock: true,
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
                                                                                {
                                                                                    id: 24,
                                                                                    price: 16.50,
                                                                                    rating: 4.5,
                                                                                    path: "/books/Hopeless.pdf",
                                                                                    title: "Hopeless",
                                                                                    author: "Colleen Hoover",
                                                                                    reviews: "1,587",
                                                                                    image: "hopeless.jpg",
                                                                                    printLength: 416,
                                                                                    language: "English",
                                                                                    PublicationDate: "8 October 2012",
                                                                                    description:"Sometimes discovering the truth can leave you more hopeless than believing the lies.",
                                                                                    inStock: true,
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
                                                                                {
                                                                                    id: 25,
                                                                                    price: 8,
                                                                                    rating: 4,
                                                                                    path: "/books/Rich Dad Poor Dad.pdf",
                                                                                    title: "Rich Dad Poor Dad",
                                                                                    author: "Robert T.Kiyosaki",
                                                                                    reviews: "8,661",
                                                                                    image: "rich-dad-poor-dad.jpg",
                                                                                    printLength: 336,
                                                                                    language: "English",
                                                                                    PublicationDate: "27 April 2017",
                                                                                    description:"A story of growing up with two dads — his real father and the father of his best friend, his rich dad — and the ways in which both men shaped his thoughts about money and investing. ",
                                                                                    inStock: true,
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
                                                                                {
                                                                                    id: 26,
                                                                                    price: 21,
                                                                                    rating: 3.5,
                                                                                    path: "/books/One True Loves.pdf",
                                                                                    title: "One True Loves",
                                                                                    author: "Taylor Jenkins Reid",
                                                                                    reviews: "2,421",
                                                                                    image: "one-true-love.jpg",
                                                                                    printLength: 342,
                                                                                    language: "English",
                                                                                    PublicationDate: "17 July 2016",
                                                                                    description:"In her twenties, Emma Blair marries her high school sweetheart, Jesse. They build a life for themselves, far away from the expectations of their parents and the people of their hometown in Massachusetts. They travel the world together, living life to the fullest and seizing every opportunity for adventure.",
                                                                                    inStock: true,
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
                                                                                {
                                                                                    id: 27,
                                                                                    price: 11.50,
                                                                                    rating: 4.5,
                                                                                    path: "/books/Think And Grow Rich.pdf",
                                                                                    title: "Think And Grow Rich",
                                                                                    author: "Napolen Hill",
                                                                                    reviews: "57,521",
                                                                                    image: "think-and-grow-rich.jpg",
                                                                                    printLength: 320,
                                                                                    language: "English",
                                                                                    PublicationDate: "1 September 2005",
                                                                                    description:" Hill contends that our thoughts become our reality, and offers a plan and principles for transforming thoughts into riches, including visualization, affirmation, creating a Master Mind group, defining a goal, and planning.",
                                                                                    inStock: true,
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
                                                                                {
                                                                                    id: 28,
                                                                                    price: 7.00,
                                                                                    rating: 4.5,
                                                                                    path: "/books/Read People Like a Book.pdf",
                                                                                    title: "Read People Like a Book",
                                                                                    author: "Patrick King",
                                                                                    reviews: "17,980",
                                                                                    image: "Read People Like a Book.jpeg",
                                                                                    printLength: 277,
                                                                                    language: "English",
                                                                                    PublicationDate: "7 December 2020",
                                                                                    description:"How to Analyze, Understand, and Predict People’s Emotions, Thoughts, Intentions, and Behaviors. ",
                                                                                    inStock: true,
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
                                                                                {
                                                                                    id: 29,
                                                                                    price: 12.50,
                                                                                    rating: 5,
                                                                                    path: "/books/Good Vibes Good Life.pdf",
                                                                                    title: "Good Vibes, Good Life",
                                                                                    author: "Vex King",
                                                                                    reviews: "22,252",
                                                                                    image: "it-ends-with-us.jpg",
                                                                                    printLength: 325,
                                                                                    language: "English",
                                                                                    PublicationDate: "4 December 2018",
                                                                                    description:"In this book, Instagram guru Vex King answers all of these questions and more. Vex overcame adversity to become a source of hope for thousands of young people, and now draws from his personal experience and his intuitive wisdom to inspire you.",
                                                                                    inStock: true, 
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
                                                                                {
                                                                                    id: 30,
                                                                                    price: 6.00,
                                                                                    rating: 4.5,
                                                                                    path: "/books/Beginning Drawing Atelier.pdf",
                                                                                    title: "Beginning Drawing Atelier",
                                                                                    author: "Juliette Aristides",
                                                                                    reviews: "661",
                                                                                    image: "beginning.jpeg",
                                                                                    printLength: 128,
                                                                                    language: "English",
                                                                                    PublicationDate: "16 April 2018",
                                                                                    description:"In this elegant and inspiring workbook, master contemporary artist and best selling author Juliette Aristides breaks down the drawing process into small, manageable lessons; presents them progressively; introduces time-tested principles and techniques in the Atelier tradition that are easily accessible.",
                                                                                    inStock: false,
                                                                            
                                                                                    category: {
                                                                                        first: false,
                                                                                        second: false,
                                                                                        third: true }
                                                                                },
]

