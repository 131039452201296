import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
         <footer className="footer">
            <div className="footer-social-media">
                <div className="footer-social-media-text">Follow us on social media</div>
                <div className="footer-social-media-icons">
                    <div className="footer-social-media-icon">
                        <i className="bi bi-instagram"></i>
                    </div>

                    <div className="footer-social-media-icon">
                        <i className="bi bi-facebook"></i>
                    </div>

                    <div className="footer-social-media-icon">
                        <i className="bi bi-twitter"></i>
                    </div>

                    <div className="footer-social-media-icon">
                        <i className="bi bi-telegram"></i>
                    </div>
                </div>
            </div>
            <div className="footer-links-wrapper">
                <div className="footer-links-item">
                    <h3 className="footer-links-item-title">Useful Links</h3>
                    <ul className="footer-links">
                        <Link to="/" className="footer-link">Home</Link>
                        <Link to="/authors" className="footer-link">Authors</Link>
                        <Link to="/about" className="footer-link">About us</Link>
                        <Link to="/contact" className="footer-link">Contact us</Link>
                        <Link to="/register" className="footer-link">Register</Link>
                    </ul>
                </div>

                <div className="footer-links-item">
                    <h3 className="footer-links-item-title">Contact Information</h3>
                    <div className="footer-address-wrapper">
                        <div className="footer-address-item">
                            <i className="bi bi-geo-alt-fill"></i>
                            Syria - Damascus 
                        </div>
                        <div className="footer-address-item">
                            <i className="bi bi-telephone-fill"></i>
                            00963929843025 
                        </div>
                        <div className="footer-address-item">
                            <i className="bi bi-envelope-fill"></i>
                            info@iqraalibrary.com
                        </div>
                    </div>
                </div>
                <div className="footer-links-item">
                    <h3 className="footer-links-item-title">About us</h3>
                    <p className="footer-description">
                     Wide selection of e-books in various fields. Easy to use and secure user interface.
                     Fast and easy process.
                    </p>
                </div>
            </div>
         </footer>
         );
}

export default Footer;
