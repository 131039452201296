import HeadingTitle from '../../components/heading-title/HeadingTitle';
import Slider from '../../components/slider/Slider';
import {books} from '../../data/books';
import BookSlider from '../../components/book-slider/BookSlider';

const HomePage = () => {
    return ( 
    <div className="home">
      <Slider />
        <HeadingTitle title="Best Selling Books" />
    <BookSlider data={books.filter(b => b.category.first)} />

        <HeadingTitle title="Latest Books"   />
    <BookSlider data={books.filter(b => b.category.second)} />

        <HeadingTitle title="Most Popular Books"  />
    <BookSlider data={books.filter(b => b.category.third)} />
    </div> );
}

export default HomePage;